import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faUser,
  faFileLines,
  faBriefcase,
  faImage,
  faEnvelope,
  faXmark,
  faGrip,
  faSun,
  faCircleHalfStroke,
} from "@fortawesome/free-solid-svg-icons";
import { faMoon } from "@fortawesome/free-regular-svg-icons";
import { HashLink as Link } from "react-router-hash-link";

function Header({ headerRef }) {
  const navMenuRef = useRef(null);
  const [icon, setIcon] = useState(faCircleHalfStroke);
  const [showChooseThemeDropdown, setShowChooseThemeDropdown] = useState(false);

  function handleMenuClick() {
    navMenuRef.current.classList.add("show-menu");
  }

  function handleCloseClick() {
    navMenuRef.current.classList.remove("show-menu");
  }

  function handleOSDefaultThemClick() {
    setIcon(faCircleHalfStroke);
    document.body.classList.remove("dark-theme");
    document.body.classList.add("os-default");
    localStorage.setItem("themeSetting", "OSDefault");
    setShowChooseThemeDropdown(false);
  }

  function handleLightThemClick() {
    setIcon(faSun);
    document.body.classList.remove("os-default");
    document.body.classList.remove("dark-theme");
    localStorage.setItem("themeSetting", "light");
    setShowChooseThemeDropdown(false);
  }

  function handleDarkThemClick() {
    setIcon(faMoon);
    document.body.classList.remove("os-default");
    document.body.classList.add("dark-theme");
    localStorage.setItem("themeSetting", "dark");
    setShowChooseThemeDropdown(false);
  }

  useEffect(() => {
    const themeSetting = localStorage.getItem("themeSetting");
    if (!themeSetting) {
      document.body.classList.remove("dark-theme");
      document.body.classList.add("os-default");
    }
    if (themeSetting === "OSDefault") {
      handleOSDefaultThemClick();
    } else if (themeSetting === "light") {
      handleLightThemClick();
    } else if (themeSetting === "dark") {
      handleDarkThemClick();
    }
  }, []);

  return (
    <header className="header" id="header" ref={headerRef}>
      <nav className="nav container">
        <Link to="/#home" className="nav__logo">
          Ruokai
        </Link>
        <div className="nav__menu" id="nav-menu" ref={navMenuRef}>
          <ul className="nav__list grid">
            <li className="nav__item">
              <Link
                to="/#home"
                className="nav__link"
                onClick={handleCloseClick}
              >
                <FontAwesomeIcon icon={faHouse} className="nav__icon" />
                Home
              </Link>
            </li>
            <li className="nav_item">
              <Link
                to="/#about"
                className="nav__link"
                onClick={handleCloseClick}
              >
                <FontAwesomeIcon icon={faUser} className="nav__icon" />
                About
              </Link>
            </li>
            <li className="nav_item">
              <Link
                to="/#skills"
                className="nav__link"
                onClick={handleCloseClick}
              >
                <FontAwesomeIcon icon={faFileLines} className="nav__icon" />{" "}
                Skills
              </Link>
            </li>
            <li className="nav_item">
              <Link
                to="/#qualification"
                className="nav__link"
                onClick={handleCloseClick}
              >
                <FontAwesomeIcon icon={faBriefcase} className="nav__icon" />{" "}
                Qualification
              </Link>
            </li>
            <li className="nav_item">
              <Link
                to="/#portfolio"
                className="nav__link"
                onClick={handleCloseClick}
              >
                <FontAwesomeIcon icon={faImage} className="nav__icon" />{" "}
                Portfolio
              </Link>
            </li>
            <li className="nav_item">
              <Link
                to="/#contact"
                className="nav__link"
                onClick={handleCloseClick}
              >
                <FontAwesomeIcon icon={faEnvelope} className="nav__icon" />{" "}
                Contact
              </Link>
            </li>
          </ul>
          <FontAwesomeIcon
            icon={faXmark}
            className="nav__close"
            onClick={handleCloseClick}
          />
        </div>
        <div className="nav__btns">
          <div
            className="theme-icon-text"
            onClick={() => setShowChooseThemeDropdown(!showChooseThemeDropdown)}
          >
            <FontAwesomeIcon
              icon={icon}
              className="theme-icon"
              id="theme-button"
            />
            <div className="theme-text">Theme</div>
          </div>
          {showChooseThemeDropdown && (
            <div className="theme-dropdown">
              <div className="theme-dropdown-icon-text">
                <FontAwesomeIcon
                  icon={faCircleHalfStroke}
                  className="theme-dropdown-icon"
                  id="theme-button"
                />
                <button onClick={handleOSDefaultThemClick}>OS Default</button>
              </div>

              <div className="theme-dropdown-icon-text">
                <FontAwesomeIcon
                  icon={faSun}
                  className="theme-dropdown-icon"
                  id="theme-button"
                />
                <button onClick={handleLightThemClick}>Light Mode</button>
              </div>

              <div className="theme-dropdown-icon-text">
                <FontAwesomeIcon
                  icon={faMoon}
                  className="theme-dropdown-icon"
                  id="theme-button"
                />
                <button onClick={handleDarkThemClick}>Dark Mode</button>
              </div>
            </div>
          )}

          <div className="nav__toggle" id="nav-toggle">
            <FontAwesomeIcon icon={faGrip} onClick={handleMenuClick} />
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
