import React from 'react'
import ContactMeInput from './ContactMeInput'

function ContactMe() {
    return (
        <section className="contact section" id="contact">
            <h2 className="section__title">Contact</h2>
            <span className="section__subtitle">Get in touch</span>

            <div className="contact__container container grid">
                <ContactMeInput />
            </div>
        </section>
    )
}

export default ContactMe